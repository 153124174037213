import React from "react";
import Header from "../../components/header/Header";
import Terms from "../../components/terms/Terms";
import { termsConditions } from "../../utils/contents/headers-contents/headerContents";

const TermsConditions = () => {
    return(
    <>
    <Header header={termsConditions} />
        <Terms />
    </>)
}

export default TermsConditions