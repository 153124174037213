import React from "react";
import "./policies.css";

const Policies = () => {
  return (
    <div className="policy-container">
      <h4>1 INTRODUCTION</h4> <br />
      <div className="sub-heading ">
        1.1 Important information and who we are
      </div>
      <div className="span-content">
        Welcome to Spade Tech Limited’s Privacy and Data Protection Policy ("
        <b>Privacy Policy</b>").
      </div>{" "}
      <div className="span-content">
        At Spade Tech Limited (“ <b>we</b> ”, “ <b>us</b>”, or “<b>our</b> ”) we
        are committed to protecting and respecting your privacy and
        Personal Data in compliance with the United Kingdom General Data
        Protection Regulation (“ <b>GDPR</b> ”), the Data Protection Act 2018
        and all other mandatory laws GDPR and regulations of the United Kingdom.{" "}
      </div>
      <div className="span-content">
        This Privacy Policy explains how we collect, process and keep your data
        safe. The Privacy Policy will tell you about your privacy rights, how
        the law protects you, and inform our employees and staff members of all
        their obligations and protocols when processing data.
      </div>
      <div className="span-content">
        The individuals from which we may gather and use data can include:
        <ul className="unOurdered">
          <li className="list-item">Customers</li>
          <li className="list-item">Suppliers</li>
          <li className="list-item">Business contacts</li>
        </ul>
      </div>
      <div className="span-content">
        and any other people that the organisation has a relationship with or
        may need to contact.
      </div>
      <div className="span-content">
        {" "}
        This Privacy Policy applies to all our employees and staff members and
        all Personal Data processed at any time by us.
      </div>
      {/*  */}
      <div className="sub-heading ">1.2 Your Data Controller</div>
      <div className="span-content">
        {" "}
        Spade Tech Limited is your Data Controller and responsible for your
        Personal Data. We are not obliged by the GDPR to appoint a data
        protection officer and have not voluntarily appointed one at this time.
        Therefore, any inquiries about your data should either be sent to us by
        email to support@spadetech.io or by post to International House, 38
        Thistle Street, Edinburgh , EH2 1EN, United Kingdom.
      </div>
      <div className="span-content">
        {" "}
        You have the right to make a complaint at any time to the Information
        Commissioner’s Office (ICO), the UK supervisory authority for data
        protection issues (www.ico.org.uk). We would, however, appreciate the
        chance to deal with your concerns before you approach the ICO so please
        contact us in the first instance.
      </div>
      {/*  */}
      <div className="sub-heading ">
        1.3 Processing data on behalf of a Controller and processors’
        responsibility to you
      </div>
      <div className="span-content">
        {" "}
        In discharging our responsibilities as a Data Controller we have
        employees who will deal with your data on our behalf (known as “
        <b>Processors</b>”). The responsibilities below may be assigned to an
        individual or may be taken to apply to the organisation as a whole. The
      </div>
      <div className="span-content">
        Data Controller and our Processors have the following responsibilities:
        <ul className="unOurdered">
          <li className="list-item">
            Ensure that all processing of Personal Data is governed by one of
            the legal bases laid out in the GDPR (see 2.2 below for more
            information);
          </li>
          <li className="list-item">
            Ensure that Processors authorised to process Personal Data have
            committed themselves to confidentiality or are under an appropriate
            statutory obligation of confidentiality;
          </li>
          <li className="list-item">
            Implement appropriate technical and organisational measures to
            ensure a level of security appropriate to the risk associated with
            the processing of Personal Data;
          </li>
          <li className="list-item">
            Obtain the prior specific or general authorisation of the Controller
            before engaging another Processor;
          </li>
          <li className="list-item">
            Assist the Controller in the fulfilment of the Controller's
            obligation to respond to requests for exercising the data subject's
            rights;
          </li>
          <li className="list-item">
            Make available to the Controller all information necessary to
            demonstrate compliance with the obligations laid down in the GDPR
            and allow for and contribute to audits, including inspections,
            conducted by the Controller or another auditor mandated by the
            Controller;
          </li>
          <li className="list-item">
            Maintain a record of all categories of processing activities carried
            out on behalf of a Controller;
          </li>
          <li className="list-item">
            Cooperate, on request, with the supervisory authority in the
            performance of its tasks;
          </li>
          <li className="list-item">
            Ensure that any person acting under the authority of the Processor
            who has access to Personal Data does not process Personal Data
            except on instructions from the Controller; and
          </li>
          <li className="list-item">
            Notify the Controller without undue delay after becoming aware of a
            Personal Data Breach.
          </li>
        </ul>
      </div>
      {/*  */}
      {/*  */}
      <h4>2 LEGAL BASIS FOR DATA COLLECTION</h4> <br />
      <div className="sub-heading ">
        2.1 Types of data / Privacy policy scope
      </div>
      <div className="span-content">
        {" "}
        “<b>Personal Data</b>” means any information about an individual from
        which that person can be identified. It does not include data where the
        identity has been removed (anonymous data).
      </div>
      <div className="span-content">
        {" "}
        We may collect, use, store and transfer different kinds of Personal Data
        about you which we have grouped together below. Not all of the following
        types of data will necessarily be collected from you but this is the
        full scope of data that we collect and when we collect it from you:
        <ul className="unOurdered">
          <li className="list-item">
            Profile/Identity Data: This is data relating to your first name,
            last name, gender, date of birth.
          </li>
          <li className="list-item">
            Contact Data: This is data relating to your phone number, addresses,
            email addresses, phone numbers.
          </li>
          <li className="list-item">
            Marketing and Communications Data: This is your preferences in
            receiving marketing information and other information from us.
          </li>
        </ul>
      </div>
      <div className="span-content">
        We do not collect any Special Categories of Personal Data about you
        (this includes details about your race or ethnicity, religious or
        philosophical beliefs, sex life, sexual orientation, political opinions,
        trade union membership, information about your health, and genetic and
        biometric data). Nor do we collect any information about criminal
        convictions and offences.
      </div>
      {/*  */}
      <div className="sub-heading ">
        2.2 The Legal Basis for Collecting That Data
      </div>
      <div className="span-content">
        There are a number of justifiable reasons under the GDPR that allow
        collection and processing of Personal Data. The main avenues we rely on
        are:
        <ul className="unOurdered">
          <li className="list-item">
            “<b>Consent</b>”: Certain situations allow us to collect your
            Personal Data, such as when you tick a box that confirms you are
            happy to receive email newsletters from us, or ‘opt in’ to a
            service.
          </li>
          <li className="list-item">
            “<b>Contractual Obligations</b>”: We may require certain information
            from you in order to fulfil our contractual obligations and provide
            you with the promised service.
          </li>
          <li className="list-item">
            “<b>Legal Compliance</b>”: We’re required by law to collect and
            process certain types of data, such as fraudulent activity or other
            illegal actions.
          </li>
          <li className="list-item">
            “<b>Legitimate Interest</b>”: We might need to collect certain
            information from you to be able to meet our legitimate interests -
            this covers aspects that can be reasonably expected as part of
            running our business, that will not have a material impact on your
            rights, freedom or interests. Examples could be your address, so
            that we know where to deliver something to, or your name, so that we
            have a record of who to contact moving forwards.
          </li>
        </ul>
      </div>
      {/*  */}
      {/*  */}
      <h4>3 HOW WE USE YOUR PERSONAL DATA </h4> <br />
      <div className="sub-heading ">3.1 Our data uses</div>
      <div className="span-content">
        We will only use your Personal Data when the law allows us to.
      </div>
      {/*  */}
      <div className="sub-heading ">3.2 Marketing and content updates</div>
      <div className="span-content">
        You will receive marketing and new content communications from us if you
        have created an account and chosen to opt into receiving those
        communications. From time to time we may make suggestions and
        recommendations to you about goods or services that may be of interest
        to you.
      </div>
      {/*  */}
      <div className="sub-heading ">3.3 Change of purpose</div>
      <div className="span-content">
        We will only use your Personal Data for the purposes for which we
        collected it, unless we reasonably consider that we need to use it for
        another reason and that reason is compatible with the original purpose.
        If you wish to get an explanation as to how the processing for the new
        purpose is compatible with the original purpose, please contact us.
      </div>
      <div className="span-content">
        If we need to use your Personal Data for an unrelated purpose, we will
        notify you and we will explain the legal basis which allows us to do so.
      </div>
      <div className="span-content">
        Please note that we may process your Personal Data without your
        knowledge or consent, in compliance with the above rules, where this is
        required or permitted by law.
      </div>
      {/*  */}
      {/*  */}
      <h4>4 YOUR RIGHTS AND HOW YOU ARE PROTECTED BY US</h4> <br />
      <div className="sub-heading ">4.1 Your legal rights</div>
      <div className="span-content">
        Under certain circumstances, you have the following rights under data
        protection laws in relation to your personal data:
        <ul className="unOurdered">
          <li className="list-item">
            <b>Right to be informed.</b> You have a right to be informed about
            our purposes for processing your personal data, how long we store it
            for, and who it will be shared with. We have provided this
            information to you in this policy.
          </li>
          <li className="list-item">
            <b>Right of access.</b> This enables you to receive a copy of the
            personal data we hold about you and to check that we are lawfully
            processing it (also known as a "data subject access request"). See
            section 4.4 below for more details on how you can make a data
            subject access request.
          </li>
          <li className="list-item">
            <b>Right to rectification.</b> You have a right to request
            correction of the personal data that we hold about you. This enables
            you to have any incomplete or inaccurate data we hold about you
            corrected, though we may need to verify the accuracy of the new data
            you provide to us.
          </li>
          <li className="list-item">
            <b>Right to erasure.</b> You have the right to ask us to delete or
            remove personal data where there is no good reason for us continuing
            to process it, where you have successfully exercised your right to
            object to processing (see below), where we may have processed your
            information unlawfully or where we are required to erase your
            personal data to comply with local law. Note, however, that we may
            not always be able to comply with your request of erasure for
            specific legal reasons which will be notified to you, if applicable,
            at the time of your request.
          </li>
          <li className="list-item">
            <b>Right to object.</b> You can object to the processing of personal
            data we hold about you. This effectively allows you to stop or
            prevent us from processing your personal data. Note that this is not
            an absolute right and it only applies in certain circumstances, for
            example:
            <li className="inner-list-item">
              <b>(i)</b> Where we are processing your personal data for direct
              marketing purposes.
            </li>
            <li className="inner-list-item">
              <b>(ii)</b> Where we are relying on a legitimate interest (or
              those of a third party) and there is something about your
              particular situation which makes you want to object to processing
              on this ground as you feel it impacts on your fundamental rights
              and freedoms.
            </li>
            <li className="inner-list-item">
              <b>(iii)</b> In some cases, we may continue processing your data
              if we can demonstrate that we have compelling legitimate grounds
              to process your information which override your rights and
              freedoms.
            </li>
          </li>

          <li className="list-item">
            Right to restrict processing. You have the right to request the
            restriction or suppression of their personal data. Note that this is
            not an absolute right and it only applies in certain circumstances:
            <li className="inner-list-item">
              <b>(i)</b> If you want us to establish the data's accuracy.
            </li>
            <li className="inner-list-item">
              <b>(ii)</b> Where our use of the data is unlawful but you do not
              want us to erase it.
            </li>
            <li className="inner-list-item">
              <b>(iii)</b> Where you need us to hold the data even if we no
              longer require it as you need it to establish, exercise or defend
              legal claims.
            </li>
            <li className="inner-list-item">
              <b>(iv)</b> You have objected to our use of your data but we need
              to verify whether we have overriding legitimate grounds to use it.
            </li>
          </li>
          <li className="list-item">
            <b>Right to data portability.</b> You have the right to request the
            transfer of your personal data to you or to a third party. If you
            make such a request, we will provide to you, or a third party you
            have chosen, your personal data in a structured, commonly used,
            machine-readable format. Note that this right only applies to
            automated information which you initially provided consent for us to
            use or where we used the information to perform a contract with you.
          </li>
        </ul>
      </div>
      <div className="span-content">
        If you wish to make a request under any of these rights, please contact
        us at support@spadetech.io.
      </div>
      {/*  */}
      <div className="sub-heading ">
        4.2 How Spade Tech Limited protects customers' Personal Data
      </div>
      <div className="span-content">
        We are concerned with keeping your data secure and protecting it from
        inappropriate disclosure. Any Personal Data collected by us is only
        accessible by a limited number of employees who have special access
        rights to such systems and are bound by obligations of confidentiality.
        If and when we use subcontractors to store your data, we will not
        relinquish control of your Personal Data or expose it to security risks
        that would not have arisen had the data remained in our possession.
        However, unfortunately no transmission of data over the internet is
        guaranteed to be completely secure. It may be possible for third parties
        not under the control of Spade Tech Limited to intercept or access
        transmissions or private communications unlawfully. While we strive to
        protect your Personal Data, we cannot ensure or warrant the security of
        any Personal Data you transmit to us. Any such transmission is done at
        your own risk. If you believe that your interaction with us is no longer
        secure, please contact us.
      </div>
      {/*  */}
      <div className="sub-heading ">4.3 Opting out of marketing promotions</div>
      <div className="span-content">
        You can ask us to stop sending you marketing messages at any time by You
        can unsubscribe from marketing communications at any time.{" "}
      </div>
      <div className="span-content">
        Where you opt out of receiving these marketing messages, we will
        continue to retain other Personal Data provided to us as a result of
        interactions with us not related to your marketing preferences.
      </div>
      {/*  */}
      <div className="sub-heading ">
        4.4 How to request your data and the process for obtaining it
      </div>
      <div className="span-content">
        You will not have to pay a fee to access your Personal Data (or to
        exercise any of the other rights). However, if your request is clearly
        unfounded, we could refuse to comply with your request.
      </div>
      <div className="span-content">
        We may need to request specific information from you to help us confirm
        your identity and ensure you have the right to access your Personal Data
        (or to exercise any of your other rights). This is a security measure to
        ensure that Personal Data is not disclosed to any person who has no
        right to receive it. We may also contact you to ask you for further
        information in relation to your request to speed up our response.
      </div>
      {/*  */}
      {/*  */}
      <h4>5 YOUR DATA AND THIRD PARTIES</h4> <br />
      <div className="sub-heading ">
        5.1 Sharing your data with third parties
      </div>
      <div className="span-content">
        We may also share Personal Data with interested parties in the event
        that Spade Tech Limited anticipates a change in control or the
        acquisition of all or part of our business or assets or with interested
        parties in connection with the licensing of our technology.
      </div>
      <div className="span-content">
        If Spade Tech Limited is sold or makes a sale or transfer, we may, in
        our sole discretion, transfer, sell or assign your Personal Data to a
        third party as part of or in connection with that transaction. Upon such
        transfer, the Privacy Policy of the acquiring entity may govern the
        further use of your Personal Data. In all other situations your data
        will still remain protected in accordance with this Privacy Policy (as
        amended from time to time).
      </div>
      <div className="span-content">
        We may share your Personal Data at any time if required for legal
        reasons or in order to enforce our terms or this Privacy Policy.
      </div>
      {/*  */}
      {/*  */}
      <h4>6 HOW LONG WE RETAIN YOUR DATA</h4> <br />
      <div className="span-content">
        We will only retain your Personal Data for as long as reasonably
        necessary to fulfil the purposes we collected it for. We may retain your
        Personal Data for a longer period than usual in the event of a complaint
        or if we reasonably believe there is a prospect of litigation in respect
        to our relationship with you.
      </div>
      {/*  */}
      {/*  */}
      <h4>7 INTERNATIONAL TRANSFER OF DATA</h4> <br />
      <div className="span-content">
        Your information may be stored and processed in the US or other
        countries or jurisdictions outside the US where Spade Tech Limited has
        facilities. By using Spade Tech Limited, you are permitting and
        consenting to the transfer of information, including Personal Data,
        outside of the US.
      </div>
      {/*  */}
      {/*  */}
      <h4>8 NOTIFICATION OF CHANGES AND ACCEPTANCE OF POLICY</h4> <br />
      <div className="span-content">
        We keep our Privacy Policy under review and will place any updates here.
        This version is dated 8 August 2022.
      </div>
      <div className="span-content">
        By using Spade Tech Limited, you consent to the collection and use of
        data by us as set out in this Privacy Policy. Continued access or use of
        Spade Tech Limited will constitute your express acceptance of any
        modifications to this Privacy Policy.
      </div>
      {/*  */}
      {/*  */}
      <h4>9 INTERPRETATION</h4> <br />
      <div className="span-content">
        All uses of the word "including" mean "including but not limited to" and
        the enumerated examples are not intended to in any way limit the term
        which they serve to illustrate. Any email addresses set out in this
        policy may be used solely for the purpose for which they are stated to
        be provided, and any unrelated correspondence will be ignored. Unless
        otherwise required by law, we reserve the right to not respond to
        emails, even if they relate to a legitimate subject matter for which we
        have provided an email address. You are more likely to get a reply if
        your request or question is polite, reasonable and there is no
        relatively obvious other way to deal with or answer your concern or
        question (e.g. FAQs, other areas of our website, etc.).
      </div>
      <div className="span-content">
        Our staff are not authorised to contract on behalf of Spade Tech
        Limited, waive rights or make representations (whether contractual or
        otherwise). If anything contained in an email from a Spade Tech Limited
        address contradicts anything in this policy, our terms or any official
        public announcement on our website, or is inconsistent with or amounts
        to a waiver of any Spade Tech Limited rights, the email content will be
        read down to grant precedence to the latter. The only exception to this
        is genuine correspondence expressed to be from the Spade Tech Limited
        legal department.
      </div>
    </div>
  );
};

export default Policies;
