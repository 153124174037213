import React from "react";
import Header from "../../components/header/Header";
import Policies from "../../components/policies/Policies";
import { privacyPolicy } from "../../utils/contents/headers-contents/headerContents";

const PrivacyPolicy = () => {
    return(
        <>
        <Header header={privacyPolicy} />
        <Policies />
        </>
    )
}

export default PrivacyPolicy