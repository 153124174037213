import header1 from "../../../assets/img/header1.svg";
import header2 from "../../../assets/img/header2.svg";
import header3 from "../../../assets/img/header3.svg";
import hero from "../../../assets/img/SpadeTech.gif";

// HOME PAGE //
export const homeHeader = {
  title: (
    <h1 className="home-header">
      Bringing <span className="hightlight">blockchain</span>
      <div> to life </div>{" "}
    </h1>
  ),
  content:
    "Offering security, blockchain development and consulting services, Spadetech is the go-to blockchain development agency.",
  img: hero,
  homeHeader: true,
};

// SECURITY PAGE //
export const securityHeader = {
  title: (
    <h2>
      Launch <span className="hightlight">Secure</span> Projects
    </h2>
  ),
  content:
    "Providing affordable, comprehensive smart contract audits that ensure the safety of your project.",
  description:
    "Our skilled technical analysts will conduct a line by line review to identify any potential security vulnerabilities. We'll test thousands of interactions between your smart contract and test blockchain to give you complete confidence that your project is secure.",
  img: header3,
  url: "SERVICES / SECURITY-AUDIT",
  extra: false,
};

// DEVELOPMENT PAGE //
export const developmentHeader = {
  title: (
    <h2>
      Your <span className="hightlight">blockchain</span> project brought to
      life
    </h2>
  ),
  content:
    "We work with you to understand your business needs and develop a bespoke solution that meets your requirements.",
  description:
    "From developing smart contracts to building decentralised applications (dApps), our team of experienced blockchain developers can help you bring your project to life. We've got a stellar track record, too, and have delivered on multiple platforms from ERC 20-based tokens to NFT games and DAOs.",
  img: header2,
  url: "SERVICES / DEVELOPMENT",
  extra: false,
};

// CONSULTANCY PAGE //
export const consultancyHeader = {
  title: (
    <h2>
      The expertise you need to launch your{" "}
      <span className="hightlight">blockchain</span> project
    </h2>
  ),
  content:
    "Launch your project with confidence. We can come onboard at any stage during the project lifecycle and assist  with strategy, planning and execution.",
  img: header1,
  url: "SERVICES / CONSULTANCY",
};

// ABOUT PAGE //
export const aboutHeader = {
  title: (
    <h2>
      Superior solutions for your <span className="hightlight">blockchain</span>{" "}
      endeavours{" "}
    </h2>
  ),
  content:
    "As experts in our field, we provide a holisitic service on all things blockchain and Web3. From auditing and security to development and consultancy, we will take your project from conception through to completion.",
};

// AUDIT PAGE //
export const auditHeader = {
  title: (
    <h1 className="home-header">
      Audits are <span className="hightlight">Coming Soon!</span>
    </h1>
  ),
  img: hero,
};

// Privacy Policy PAGE //
export const privacyPolicy = {
  title: (
    <h2>
      <span className="hightlight">Website Privacy Policy </span>{" "}
    </h2>
  ),
  // content: "As experts in our field, we provide a holisitic service on all things blockchain and Web3. From auditing and security to development and consultancy, we will take your project from conception through to completion.",
};

// Terms And Conditions //
export const termsConditions = {
    title: (
      <h2>
        <span className="hightlight">Terms and Conditions</span>{" "}
      </h2>
    ),
    // content: "As experts in our field, we provide a holisitic service on all things blockchain and Web3. From auditing and security to development and consultancy, we will take your project from conception through to completion.",
  };
