import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import "./audit.css";

import {
  auditHeader,
  securityHeader,
} from "../../utils/contents/headers-contents/headerContents";
import { securityCard } from "../../utils/contents/cards-contents/securityAuditCard";
import { securityOffers } from "../../utils/contents/offers-list/offersContents";
import ServicesSolutions from "../../components/services/ServicesSolutions";
import Offers from "../../components/services/Offers";
import WorkSteps from "../../components/services/WorkSteps";
import ServicesDifferences from "../../components/services/ServicesDifferences";
import { useHistory } from "react-router-dom";


const Audit = () => {
  // const history = useHistory();
  

  // useEffect(() => {
  //   history.push('login');
  // });

  return (
    <>
      <Header header={securityHeader} audit />

      {/* <h1>Card section's font size need to be improved</h1> */}
      <ServicesSolutions card={securityCard} />
      <Offers page={securityOffers} />
      <WorkSteps />
      <ServicesDifferences cards={securityCard.section_3} /> 
    </>
  );
};

export default Audit;
