import React from "react";
import "./terms.css";

const Terms = () => {
  return (
    <div className="terms-container">
      <div className="sub-heading">Welcome to Spade Tech!</div>
      <div className="para-content">
        These terms and conditions outline the rules and regulations for the use
        of Spade Tech Limited Website, located at <b>spadetech.io.</b>
      </div>
      <div className="para-content">
        By accessing this website we assume you accept these terms and
        conditions. Do not continue to use Spade Tech if you do not agree to take
        all of the terms and conditions stated on this page.
      </div>
      <div className="para-content">
        The following terminology applies to these Terms and Conditions, Privacy
        Statement and Disclaimer Notice and all Agreements: "Client", "You" and
        "Your" refers to you, the person log on this website and compliant to
        the Company’s terms and conditions. "The Company", "Ourselves", "We",
        "Our" and "Us", refers to our Company. "Party", "Parties", or "Us",
        refers to both the Client and ourselves. All terms refer to the offer,
        acceptance and consideration of payment necessary to undertake the
        process of our assistance to the Client in the most appropriate manner
        for the express purpose of meeting the Client’s needs in respect of
        provision of the Company’s stated services, in accordance with and
        subject to, prevailing law of Netherlands. Any use of the above
        terminology or other words in the singular, plural, capitalization
        and/or he/she or they, are taken as interchangeable and therefore as
        referring to the same.
      </div>

      {/*  */}
      <div className="sub-heading">Cookies:</div>
      <div className="para-content">
        We employ the use of cookies. By accessing Spade Tech Limited, you agreed to use
        cookies in agreement with the Spade Tech Limited's Privacy Policy
      </div>
      <div className="para-content">
        Most interactive websites use cookies to let us retrieve the user’s
        details for each visit. Cookies are used by our website to enable the
        functionality of certain areas to make it easier for people visiting our
        website. Some of our affiliate/advertising partners may also use cookies
      </div>

      {/*  */}
      <div className="sub-heading">Licence:</div>
      <div className="para-content">
        Unless otherwise stated, Spade Tech Limited and/or its licensors own the
        intellectual property rights for all material on Spade Tech Limited. All
        intellectual property rights are reserved. You may access this from
        Spade Tech Limited for your own personal use subjected to restrictions set in
        these terms and conditions.
      </div>

      {/*  */}
      <div className="sub-heading">You must not:</div>
      <div className="para-content">
        Republish material from Spade Tech <br />
        Sell, rent or sub-license material from Spade Tech <br />
        Reproduce, duplicate or copy material from Spade Tech <br />
        Redistribute content from Spade Tech <br />
      </div>
      <div className="para-content">
        Parts of this website offer an opportunity for users to post and
        exchange opinions and information in certain areas of the website.
        Spade Tech do not filter, edit, publish or review Comments prior to their
        presence on the website. Comments do not reflect the views and opinions
        of Spade Tech Limited,its agents and/or affiliates. Comments reflect the views
        and opinions of the person who post their views and opinions. To the
        extent permitted by applicable laws, Spade Tech shall not be liable for
        the Comments or for any liability, damages or expenses caused and/or
        suffered as a result of any use of and/or posting of and/or appearance
        of the Comments on this website.
      </div>
      <div className="para-content">
        Spade Tech Limited reserves the right to monitor all Comments and to remove any
        Comments which can be considered inappropriate, offensive or causes
        breach of these Terms and Conditions.
      </div>

      {/*  */}
      <div className="sub-heading">You warrant and represent that:</div>
      <div className="para-content">
        You are entitled to post the Comments on our website and have all
        necessary licenses and consents to do so.
      </div>
      <div className="para-content">
        The Comments do not invade any intellectual property right, including
        without limitation copyright, patent or trademark of any third party.
      </div>
      <div className="para-content">
        The Comments do not contain any defamatory, libellous, offensive,
        indecent or otherwise unlawful material which is an invasion of privacy.
      </div>
      <div className="para-content">
        The Comments will not be used to solicit or promote business or custom
        or present commercial activities or unlawful activity
      </div>
      <div className="para-content">
        You hereby grant Spade Tech Limited a non-exclusive licence to use, reproduce,
        edit and authorise others to use, reproduce and edit any of your
        Comments in any and all forms, formats or media.
      </div>

      {/*  */}
      <div className="sub-heading">Hyperlinking to our Content:</div>
      <div className="para-content">
        The following organizations may link to our Website without prior
        written approval:
        <ul className="unOurdered">
          <li className="list-item">Government agencies.</li>
          <li className="list-item">Search engines.</li>
          <li className="list-item">News organizations</li>
          <li className="list-item">
            Online directory distributors may link to our Website in the same
            manner as they hyperlink to the Websites of other listed businesses.
          </li>
          <li className="list-item">
            System wide Accredited Businesses except soliciting non-profit
            organizations, charity shopping malls, and charity fundraising
            groups which may not hyperlink to our Web site. These organizations
            may link to our home page, to publications or to other Website
            information so long as the link: (a) is not in any way deceptive;
            (b) does not falsely imply sponsorship, endorsement or approval of
            the linking party and its products and/or services; and (c) fits
            within the context of the linking party’s site.
          </li>
          <li className="list-item">
            We may consider and approve other link requests from the following
            types of organizations: commonly-known consumer and/or business
            information sources; <br />
            dot.com community sites; <br />
            associations or other groups representing charities; <br />
            online directory distributors; <br />
            internet portals; <br />
            accounting, law and consulting firms; and <br />
            educational institutions and trade associations.
          </li>
          <li className="list-item">
            We will approve link requests from these organizations if we decide
            that: (a) the link would not make us look unfavorably to ourselves
            or to our accredited businesses; (b) the organization does not have
            any negative records with us; (c) the benefit to us from the
            visibility of the hyperlink compensates the absence of Spade Tech Limited;
            and (d) the link is in the context of general resource information.
          </li>
          <li className="list-item">
            These organisations may link to our home page so long as the link:
            (a) is not in any way deceptive; (b) does not falsely imply
            sponsorship, endorsement or approval of the linking party and its
            products or services; and (c) fits within the context of the linking
            party’s site.
          </li>
          <li className="list-item">
            If you are one of the organisations listed in paragraph 2 above and
            are interested in linking to our website, you must inform us by
            sending an email to Spade Tech. Please include your name, your
            organisation name, contact information as well as the URL of your
            site, a list of any URLs from which you intend to link to our
            Website, and a list of the URLs on our site to which you would like
            to link. Wait 1-2 weeks for a response.
          </li>
        </ul>
      </div>

      {/*  */}
      <div className="sub-heading">
        Approved organisations may hyperlink to our Website as follows:
      </div>
      <div className="para-content">
        By use of our corporate name; or <br />
        By use of the uniform resource locator being linked to; <br />
        By use of any other description of our Website being linked to that
        makes sense within the context and format of content on the linking
        party’s site. <br />
        No use of Spade Tech's logo or other artwork will be allowed for linking
        absent a trademark licence agreement. <br />
      </div>

      {/*  */}
      <div className="sub-heading">iFrames:</div>
      <div className="para-content">
        Without prior approval and written permission, you may not create frames
        around our Webpages that alter in any way the visual presentation or
        appearance of our Website
      </div>

      {/*  */}
      <div className="sub-heading">Content Liability:</div>
      <div className="para-content">
        We shall not be hold responsible for any content that appears on your
        Website. You agree to protect and defend us against all claims that are
        rising on your Website. No link(s) should appear on any Website that may
        be interpreted as libellous, obscene or criminal, or which infringes,
        otherwise violates, or advocates the infringement or other violation of,
        any third party rights.
      </div>

      {/*  */}
      <div className="sub-heading">Your Privacy:</div>
      <div className="para-content">Please read Privacy Policy</div>

      {/*  */}
      <div className="sub-heading">Reservation of Rights:</div>
      <div className="para-content">
        We reserve the right to request that you remove all links or any
        particular link to our Website. You approve to immediately remove all
        links to our Website upon request. We also reserve the right to amend
        these terms and conditions and its linking policy at any time. By
        continuously linking to our Website, you agree to be bound to and follow
        these linking terms and conditions
      </div>

      {/*  */}
      <div className="sub-heading">Removal of links from our website:</div>
      <div className="para-content">
        If you find any link on our Website that is offensive for any reason,
        you are free to contact and inform us any moment. We will consider
        requests to remove links but we are not obligated to or so or to respond
        to you directly.
      </div>
      <div className="para-content">
        We do not ensure that the information on this website is correct, we do
        not warrant its completeness or accuracy; nor do we promise to ensure
        that the website remains available or that the material on the website
        is kept up to date.
      </div>

      {/*  */}
      <div className="sub-heading">Disclaimer: </div>
      <div className="para-content">
        To the maximum extent permitted by applicable law, we exclude all
        representations, warranties and conditions relating to our website and
        the use of this website. Nothing in this disclaimer will:
      </div>
      <div className="para-content">
        limit or exclude our or your liability for death or personal injury;{" "}
        <br />
        limit or exclude our or your liability for fraud or fraudulent
        misrepresentation; <br />
        limit any of our or your liabilities in any way that is not permitted
        under applicable law; or exclude any of our or your liabilities that may
        not be excluded under applicable law. <br />
        The limitations and prohibitions of liability set in this Section and
        elsewhere in this disclaimer: (a) are subject to the preceding
        paragraph; and (b) govern all liabilities arising under the disclaimer,
        including liabilities arising in contract, in tort and for breach of
        statutory duty
      </div>
      <div className="para-content">
        As long as the website and the information and services on the website
        are provided free of charge, we will not be liable for any loss or
        damage of any nature.
      </div>
    </div>
  );
};

export default Terms;
