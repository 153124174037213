import member1 from "../../../assets/img/Kris Miller CEO.png";
import member2 from "../../../assets/img/prabhu__01__01-modified-min-modified.png";
import member4 from "../../../assets/img/josh-min-modified.png";
import member3 from "../../../assets/img/Yash Mandil CTO-min-modified.png";
import member5 from "../../../assets/img/arun BW-min-modified.png";
import member6 from "../../../assets/img/avatar.png";
import member7 from "../../../assets/img/Durga Admin Head-min-modified.png";
import SonalImage from "../../../assets/img/SonalKurmi.svg";
import VivekImage from "../../../assets/img/Vivek.svg";
import DhananjayImage from "../../../assets/img/Dhananjay.svg";
import BhanuImage from "../../../assets/img/BhanuPrasad.svg";
import AdithyanImage from "../../../assets/img/Adithyan.png";
import ManavImage from "../../../assets/img/Manav.svg";
import ObirijahImage from "../../../assets/img/Obirijah.svg";
import LanceImage from "../../../assets/img/Lance.svg";
import TaiwoImage from "../../../assets/img/Taiwo.svg";
import DavidImage from "../../../assets/img/David.svg";

export const members = [
  {
    id: 1,
    name: "Josh Scott",
    position: "Co-Founder and CEO",
    avatar: member4,
  },
  {
    id: 2,
    name: "David Owusu",
    position: "COO",
    avatar: DavidImage,
  },
  {
    id: 3,
    name: "Kris Miller",
    position: "Co-Founder - Strategy Advisor",
    avatar: member1,
  },
  {
    id: 4,
    name: "Yash Mandil",
    position: "Co-Founder - Technical Advisor",
    avatar: member3,
  },
  {
    id: 5,
    name: "Prabhu KP",
    position: "Co-Founder - Business Advisor",
    avatar: member2,
  },
  {
    id: 6,
    name: "Durga",
    position: "Project Management",
    avatar: member7,
  },
  {
    id: 7,
    name: "Sonal Kurmi",
    position: "Business developement & HR HEAD",
    avatar: SonalImage,
  },
  {
    id: 8,
    name: "Lance ",
    position: "Lead Full Stack Developer",
    avatar: LanceImage,
  },
  {
    id: 9,
    name: "Dhananjay Mukerji",
    position: "Product Designer",
    avatar: DhananjayImage,
  },
  {
    id: 10,
    name: "Taiwo Gbadegesin",
    position: "Data Analyst",
    avatar: TaiwoImage,
  },
  {
    id: 11,
    name: "Obirijah Tochukwu",
    position: "Frontend developer",
    avatar: ObirijahImage,
  },
];
